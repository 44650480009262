import React from 'react'
import Helmet from 'react-helmet'
import Contact from '../components/Contact'
import Layout from '../components/layout'
import Address from '../components/Address'

const Page = () => {
  const siteTitle =
    'Psychoterapia Mosty - seminaria teoretyczno-superwizyjne we Wrocławiu'
  const siteDescription =
    'Psychoterapia Wrocław, doświadczeni psychoterapeuci. Seminaria psychoterapeutyczne.'

  return (
    <Layout>
      <Helmet>
        <title>{siteTitle}</title>
        <meta name="description" content={siteDescription} />
      </Helmet>

      <div id="main">
        <section id="one">
          <header className="major">
            <h2>Seminaria teoretyczno-superwizyjne</h2>
          </header>
        </section>

        <section>
          <p>
            W MOSTACH odbywają się seminaria teoretyczno-superwizyjne. Spotkania
            przeznaczone są dla psychoterapeutów chcących pogłębić rozumienie
            pacjentów korzystając z teorii i myśli psychoanalitycznych.
            Spotkania odbywają się raz w miesiącu w soboty w terminie od
            września do czerwca.
          </p>
          <p>
            Prowadzący:
            <ul>
              <li>
                <strong>Piotr Dworczyk</strong> - psychoanalityk Polskiego
                Towarzystwa Psychoanalitycznego oraz International
                Psychoanalytical Assaciation, psychoterapeuta szkoleniowy i
                superwizor Polskiego Towarzystwa Psychoterapii
                Psychoanalitycznej.
              </li>
              <li>
                <strong>Marcin Wiśniewski</strong> - psychoanalityk, członek
                Polskiego Towarzystwa Psychoanalitycznego oraz psychoterapeuta
                psychoanalityczny, członek Polskiego Towarzystwa Psychoterapii
                Psychoanalitycznej.
              </li>
            </ul>
          </p>
          <p>
            Kontakt w sprawie zapisów z Panem Piotrem Dworczykiem:
            pjdworczyk@gmail.com
          </p>
        </section>

        <section id="three">
          <Address />
        </section>
      </div>
    </Layout>
  )
}

export default Page
